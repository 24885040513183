import React from 'react';
import {Box, Text, Image, Icon, Link} from "@chakra-ui/react";
import portrait from './portrait.jpg'
import {RiLinkedinLine, RiGithubLine} from "react-icons/all";

function Root() {
    return(<>
        <Box h='100vh' align="center">
            <Box h='30vh'/>
            <Image
                borderRadius='full'
                boxSize='150px'
                src={portrait}
                alt='Ojas Mandlekar'
            />
            <Box h='1vh'/>
            <Text fontSize="5xl">
                Hey, I'm Ojas.
            </Text>
            <Text fontSize="2xl">
                an engineer with a passion for computing
            </Text>
            <Box marginTop="10px">
                <Link href="https://github.com/ojas121" isExternal={true}>
                    <Icon boxSize={6} as={RiGithubLine}/>
                </Link> &nbsp; &nbsp;
                <Link href="https://www.linkedin.com/in/ojasmandlekar/" isExternal={true}>
                    <Icon boxSize={6} as={RiLinkedinLine}/>
                </Link>
            </Box>
        </Box>
        {/*<Text>
            Test text
        </Text>*/}
    </>)
}

export default Root