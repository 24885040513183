import React, {useEffect} from 'react';
import '@fontsource/dm-sans/400.css'
import {
  ChakraProvider,
  useToast
} from '@chakra-ui/react';
import theme from "./theme";
import Root from "./Root";



function App() {
    const toast = useToast()
    useEffect(() => {
        toast({
            title: 'Website under construction',
            description: 'Feel free to check out my LinkedIn and GitHub in the meantime',
            duration: null,
            isClosable: false
        })
    }, [toast])
  return (
    <ChakraProvider theme={theme}>
        <Root/>
    </ChakraProvider>
  );
}

export default App;
